import React from "react";
import { FiGitPullRequest , FiTerminal , FiTarget , FiLayers } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiGitPullRequest />,
        title: 'Make',
        description: 'Technology is in our DNA. We help you craft awesome digital products, focussing on fast iterations and rapid feedback.'
    },
    {
        icon: <FiTerminal />,
        title: 'Run',
        description: 'Products are not alive until they are released. We manage the infrastructure to operate your products in a reliable and highly available manner.'
    },
    {
        icon: <FiTarget />,
        title: 'Assemble',
        description: 'Great companies are collections of highly capable individuals and teams. We help you find, develop and retain the best talent.'
    },
    {
        icon: <FiLayers />,
        title: 'Scale',
        description: 'With the right product and team in place, we lift you up to the next stage of growth.'
    }
]

function ServiceTwo() {
    let title = 'WhoWeAre';
    let description = 'AGV is a venture builder with a focus on deep tech business models. Here is how we can provide value.';
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                        <h2 className="title" id="who">{title}</h2>
                        <p>{description}</p>
                    </div>
                </div>
                <div className="col-lg-8 col-12 mt_md--50">
                    <div className="row service-one-wrapper">
                        {ServiceList.map( (val , i) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ServiceTwo;
