import React from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import Helmet from "../component/common/Helmet";

function MainDemo() {
  return (
    <div className="active-dark">
      <Helmet pageTitle="Alex Gutjahr Ventures" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      <div className="slider-wrapper">
        <SliderOne />
      </div>

      <div className="service-area ptb--80  bg_image bg_image--3">
        <div className="container">
          <ServiceTwo />
        </div>
      </div>

      {/*
      <div className="rn-brand-area brand-separation pb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
  */}

      <Footer />
    </div>
  );
}

export default MainDemo;
