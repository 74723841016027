import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const Legal = () => {
  return (
    <>
      <PageHelmet pageTitle="Legal Notice" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Legal Notice"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Columns Area  */}
        <div className="rn-columns-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-column">
                  <h4 className="tilte">
                    Legal Notice according to § 5 TMG (Telemediengesetz)
                  </h4>
                  <p>
                    Alex Gutjahr Ventures GmbH
                    <br />
                    Scanbox #09285<br />
                    Ehrenbergstr. 16a<br />
                    10245 Berlin
                    <br />
                    <br />
                    Represented by Alexander Gutjahr (Managing Director)
                    <br />
                    <br />
                    Commercial register: HRB 35727
                    <br />
                    Registration court: Potsdam
                    <br />
                  </p>
                  <h4 className="tilte">Contact</h4>
                  <p>
                    Mail: hello@alexgutjahr.ventures
                  </p>
                  <h4 className="tilte">VAT Identification Number</h4>
                  <p>DE283990873</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Columns Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};

export default Legal;
