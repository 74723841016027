import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function Footer() {
  return (
    <React.Fragment>
      <footer className="footer-area">
        <div className="footer-wrapper">
          <div className="row align-items-end row--0">
            <div className="col-lg-12">
              <div className="footer-left" data-black-overlay="12">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-2">
                    <h5>Alex Gutjahr Ventures GmbH</h5>
                    <span>Scanbox #09285</span>
                    <br />
                    <span>Ehrenbergstr. 16a</span>
                    <br />
                    <span>10245 Berlin</span>
                  </div>
                  <div className="col-lg-2">
                    <br />
                  </div>
                  <div className="col-lg-2">
                    <h5>Contact</h5>
                    <span>hello@alexgutjahr.ventures</span>
                    <br />
                    <span>
                      <Link to="/legal">Legal Notice</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
