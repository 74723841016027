import React from "react";
import { Helmet } from "react-helmet";

function PageHelmet({ pageTitle }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Alex Gutjahr Ventures: Building Companies. Making Things."
        />
      </Helmet>
    </React.Fragment>
  );
}

export default PageHelmet;
