import React, { Component } from "react";
import Particles from "react-particles-js";

const getParticles = (size) => {
  if (size > 1280) {
    return { number: 100, size: 4 };
  } else if (size > 720) {
    return { number: 50, size: 3 };
  } else {
    return { number: 25, size: 2 };
  }
};

class SliderOne extends Component {
  render() {
    const { number, size } = getParticles(window.innerWidth);

    return (
      <div className="slider-activation">
        <div
          className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
          data-black-overlay="6"
          // style={{
          //   width: "100%",
          //   height: "100%",

          // }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{ width: "100%", height: "100%" }}
              //   className="inner"
              className="slider-activation slider-creative-agency with-particles"
              id="home"
            >
              <div className="frame-layout__particles">
                <Particles
                  params={{
                    particles: {
                      number: {
                        value: number,
                      },
                      size: {
                        value: size,
                      },
                    },
                    interactivity: {
                      events: {
                        onhover: {
                          enable: true,
                          mode: "repulse",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container position-relative" style={{ zIndex: 10 }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h1 className="title theme-gradient">
                    Building Companies. Making Things.{" "}
                  </h1>
                </div>
              </div>
            </div>
            {/* Start Service Area 
            <div className="service-wrapper service-white">
              <ServiceOne />
            </div>
            {/* End Service Area */}
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    );
  }
}
export default SliderOne;
